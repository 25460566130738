
import Vue from "vue";

export default Vue.extend({
  name: "Button",
  props: {
    color: {
      type: String,
    },
    title: {
      type: String,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    text: {
      type: Boolean,
      default: false,
    },
    iconOnly: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
    },
    leftIcon: {
      type: String,
    },
    leftIconColor: {
      type: String,
    },
    rightIcon: {
      type: String,
    },
    rightIconColor: {
      type: String,
    },
    icon: {
      type: String,
    },
    iconColor: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    displayShadow: {
      type: Boolean,
      default: false,
    },
  },
});
