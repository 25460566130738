import BaseModel from "@/data/models/base/base-model";
import RoleHelper from "@/helpers/roles-utils";
import TypeUtils from "@/helpers/type-utils";
import UserRole from "../enums/roles";

export default class User extends BaseModel {
  email: string | null = null;
  roles: Array<string> = [] as string[];
  role: string | null = null;

  static map(data: any): User {
    const user: User = new User();
    user.id = TypeUtils.hasStringOrDefault(data?.id);
    user.email = TypeUtils.hasStringOrDefault(data?.email);
    user.roles = TypeUtils.hasArrayOrDefault(
      data?.["https://hasura.io/jwt/claims"]?.["x-hasura-allowed-roles"]) as string[];
    user.role = RoleHelper.getHigherRole(user?.roles);
    return user;
  }

  isTechBO(): boolean {
    return this.roles.includes(UserRole.TECH);
  }

  ismarketingBO(): boolean {
    return this.roles.includes(UserRole.MARKETING);
  }

  isAdminBO(): boolean {
    return this.roles.includes(UserRole.ADMIN);
  }

  isAllowed(): boolean {
    return this.isAdminBO() || this.isTechBO() || this.ismarketingBO();
  }
  
}
