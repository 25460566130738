import gql from "graphql-tag";

export const INSERT_LOG = gql`
  mutation insertLog(
    $type: event_type_enum = sync
    $user_id: String = ""
    $on_id: String = ""
    $comment: String = ""
  ) {
    insert_back_office_events_one(
      object: {
        type: $type
        user_id: $user_id
        on_id: $on_id
        comment: $comment
      }
    ) {
      id
    }
  }
`;
