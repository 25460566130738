
import Vue from "vue";

export default Vue.extend({
  name: "Input",
  props: {
    value: {},
    id: {
      type: String,
    },
    name: {
      type: String,
    },
    autocomplete: {
      type: String,
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    secured: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
    },
    type: {
      type: String,
      default: "text",
    },
    prependIcon: {
      type: String,
    },
    prependText: {
      type: String,
    },
    prependImage: {
      type: String,
      default: "",
    },
    appendIcon: {
      type: String,
    },
    readonly: {
      type: Boolean,
    },
    backgroundColor: {
      type: String,
    },
  },
  computed: {
    content: {
      get(): any {
        return this.value;
      },
      set(newValue: any) {
        this.$emit("input", newValue);
      },
    },
    getPrependImageSrc() {
      return require(`@/assets/images/${this.prependImage}`);
    },
  },
  methods: {
    updateValue(e: InputEvent) {
      this.$emit("input", e);
    },
  },
});
