
import Vue from "vue";

export default Vue.extend({
  name: "Button",
  props: {
    modelValue: {},
    value: {
      type: String,
    },
    label: {
      type: String,
      required: true,
    },
    color: {
      type: String,
    },
  },
  methods: {
    updateInput(event: any) {
      if (this.modelValue instanceof Array) {
        const newValue = [...this.modelValue];
        if (newValue.indexOf(this.value) === -1) {
          newValue.push(this.value);
        } else {
          newValue.splice(newValue.indexOf(this.value), 1);
        }
        this.$emit("change", newValue);
      }
    },
  },
});
