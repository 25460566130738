import TokenHelper from "@/helpers/token-utils";
import AuthDAO from "../dao/auth-dao";
import User from "../models/user";

export default class AuthService {
  private static authDao = new AuthDAO();

  public static get isSignedIn(): Promise<boolean> {
    return this.authDao.isSignedIn();
  }

  static signIn(email: string, password: string): Promise<any> {
    return this.authDao.signIn(email, password);
  }

  static signUp(email: string, password: string): boolean {
    return false;
  }

  static resetPassword(email: string): boolean {
    return false;
  }

  static signOut(): Promise<any> {
    return this.authDao.signOut();
  }

  static refreshToken(token: string) {
    return this.authDao.refreshToken(token);
  }

  static getUser(): User {
    return User.map(TokenHelper.decoded(localStorage.getItem("access_token")) as any)
  }
}
