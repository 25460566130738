import gql from "graphql-tag";

export const GET_TAGS_WITH_SPECIFIC_TYPE = gql`
  query getTags($type: tag_type_enum = dentist_status) {
    tags(where: { type: { _eq: $type } }) {
      id
      name
      type
    }
  }
`;
