
import Vue from "vue";
import Dialog from "@/components/molecules/Dialog.vue";
import News from "@/data/models/news";

export default Vue.extend({
  name: "DeleteNewsDialog",
  components: {
    Dialog,
  },
  computed: {},
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    news: {
      type: News,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
    deleteNew() {
      this.loading = true;
      this.$services.news
        .deleteNew(this.news?.id || "")
        .then(() => {
          this.$notify(this.$t("news.deleteDialog.success").toString(), {
            color: "primary",
          });
          this.$services.logs.insertNewsDeletionLog(this.news)
          this.$emit("reload");
          this.close();
        })
        .catch(() => {
          this.$notify(this.$t("common.error").toString(), { color: "red" });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
});
