import gql from "graphql-tag";

export const GET_FIRMWARES = gql`
  query GetFirmwares {
    firmware(distinct_on: version) {
      version
      version_string
      message
      eligible_qs
      targeted_qs
      installed_qs
      created_at
    }
  }
`;

export const GET_FIRMWARE = gql`
  query GetFirmware($version: Int = 3) {
    firmware(where: { version: { _eq: $version } }, distinct_on: version) {
      version
      version_string
      message
      eligible_qs
      targeted_qs
      installed_qs
    }
  }
`;

export const UPDATE_MESSAGE_AND_VERSION_STRING_FIRMWARE = gql`
  mutation UpdateMessageAndVersionStringFirmware(
    $version: Int = 10
    $message: jsonb = ""
    $versionString: String = ""
  ) {
    update_firmware(
      where: { version: { _eq: $version } }
      _set: { message: $message, version_string: $versionString }
    ) {
      affected_rows
    }
  }
`;
