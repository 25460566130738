import StorageService from "@/data/services/storage-service";
import Vue from "vue";
import Vuetify, { VSnackbar, VBtn, VIcon } from "vuetify/lib";
import Notify from "@/plugins/notify";

Vue.use(Notify, { $vuetify: new Vuetify().framework });

Vue.use(Vuetify, {
  components: {
    VSnackbar,
    VBtn,
    VIcon,
  },
});

const darkMode = JSON.parse(
  StorageService.get("darkMode") || "false"
) as boolean;

export default new Vuetify({
  theme: {
    dark: darkMode,
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: {
          base: "#0da9cf",
          darken1: "#3a34b3",
          darken2: "#322c99"
        },
        primaryLowOpacity: "#E7F6FA",
        secondary:{
          base : "#66B12D"
        },
        blue: "#0da9cf",
        lightGrey: "#f8f8fc",
        background: "#F8F8FC",
        authBackground: "#E6EDFD",
        red: "#FF0B19"
      },
      dark: {
        primary: {
          base: "#0da9cf",
          darken1: "#3a34b3",
          darken2: "#322c99",
        },
        primaryLowOpacity: "#E7F6FA",
        secondary:{
          base : "#66B12D"
        },
        blue: "#0da9cf",
        lightGrey: "#f8f8fc",
        background: "#F8F8FC",
        authBackground: "#E6EDFD",
        red: "#FF0B19"
      },
    },
  },
});
