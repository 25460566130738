
import Vue from "vue";
import News from "@/data/models/news";
import { emailValidator } from "@/helpers/rules-validator";
import Country from "@/data/models/country";
import { NodeHtmlMarkdown, NodeHtmlMarkdownOptions } from "node-html-markdown";
const MarkdownIt = require("markdown-it");
const md = new MarkdownIt();
import Tag from "@/data/models/tag";
import TagType from "@/data/enums/tag-type";
import ConfirmDialog from "@/components/organisms/new/ConfirmDialog.vue";
import SentDialog from "@/components/organisms/new/SentDialog.vue";

export default Vue.extend({
  name: "New",
  components: {
    ConfirmDialog,
    SentDialog,
  },
  data() {
    return {
      news: new News(),
      emailRules: emailValidator(
        this.$t("validators.emailRequired").toString(),
        this.$t("validators.emailBadlyFormatted").toString()
      ),
      loading: true,
      selectedLang: [] as any[],
      categoriesInput: {
        fr: "",
        en: "",
        de: "",
      },
      specialities: [] as Tag[],
      pratiques: [] as Tag[],
      customToolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }, "bold", "italic"],
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
        ["link"],
      ],
      countries: [] as Country[],
      catergoriesError: "",
      specialitiesError: "",
      pratiquesError: "",
      countriesError: "",
      form: {
        title: {
          fr: "",
          en: "",
          de: "",
        },
        image: {
          fr: (null as unknown) as File,
          en: (null as unknown) as File,
          de: (null as unknown) as File,
        },
        content: {
          fr: "",
          en: "",
          de: "",
        },
        categories: {
          fr: [] as string[],
          en: [] as string[],
          de: [] as string[],
        },
        specialities: [] as Tag[],
        pratiques: [] as Tag[],
        countries: [] as number[],
        publicationDate: (null as unknown) as Date,
      },
      disabled: false,
      newNotExisting: false,
      confirmDialogVisible: false,
      sentDialogVisible: false,
      images: {
        fr: "",
        en: "",
        de: "",
      },
      initialPublicationDate: (null as unknown) as Date,
      openedPanel: undefined as number | undefined,
    };
  },
  computed: {
    isANewUpdate(): boolean {
      return this.$route.params.id !== "create";
    },
    title(): string {
      let headerTitle = `${this.$t("new.title")}`;
      if (this.isANewUpdate) {
        headerTitle = `${headerTitle} / ${this.news.titleString}`;
      }
      return headerTitle;
    },
  },
  mounted() {
    if (this.isANewUpdate) {
      this.initialPublicationDate = this.news.publishedAt || (null as any);
      this.$services.news
        .getNew(this.$route.params.id)
        .then((data: News | null) => {
          if (!data) {
            return;
          }
          this.news = data;
          this.loading = false;
          this.form.title = this.news.title as any;
          for (const prop in this.news.categories) {
            (this.form.categories as any)[prop] = (this.news.categories as any)[
              prop
            ];
          }
          this.form.publicationDate = this.news.publishedAt || (null as any);
          this.form.specialities = this.news.specialities;
          this.form.pratiques = this.news.pratiques;
          this.form.countries = this.news.countries as number[];
          this.form.content = Object.keys(this.news.content as any).reduce(
            (cur, key) => {
              return Object.assign(cur, {
                [key]: md.render((this.news?.content as any)[key]),
              });
            },
            {}
          ) as any;

          this.selectedLang = Object.keys(this.news.content as any);

          for (const key in this.news.image) {
            const lang = (this.news.image as any)[key].lang as
              | "fr"
              | "en"
              | "de";
            const fileId = (this.news.image as any)[key].file_id;
            const imageURL = this.$services.hasuraStorage.getPublicUrl(fileId);
            this.images[lang] = imageURL;
            fetch(imageURL)
              .then((res) => res.blob())
              .then((blob) => {
                const file = new File(
                  [blob],
                  `${this.news.id}_${(this.news.image as any)[key].lang}`,
                  {
                    type: "image/png",
                  }
                );
                this.form.image[lang] = file;
              });
          }
        })
        .catch(() => {
          this.newNotExisting = true;
        });
    }

    this.$services.countries.getCountries().then((data: Country[]) => {
      this.countries = data;
    });

    this.$services.tags
      .getTagsWithSpecificType(TagType.SPECIALITY)
      .then((result: any) => {
        this.specialities = result;
        if (!this.isANewUpdate) {
          this.form.specialities = this.specialities;
        }
      });
    this.$services.tags
      .getTagsWithSpecificType(TagType.DENTIST_STATUS)
      .then((result: any) => {
        this.pratiques = result;
        if (!this.isANewUpdate) {
          this.form.pratiques = this.pratiques;
        }
      });
  },
  methods: {
    addCategory(lang: string) {
      if (
        (this.form.categories as any)[lang].indexOf(
          (this.categoriesInput as any)[lang]
        ) === -1 &&
        (this.categoriesInput as any)[lang] !== ""
      ) {
        (this.form.categories as any)[lang].push(
          (this.categoriesInput as any)[lang]
        );
      } else {
        this.catergoriesError = this.$t(
          "new.error.categoryAlreadyIn"
        ).toString();
      }

      (this.categoriesInput as any)[lang] = "";
    },
    addCategoryOnEnter(i: any){
       if (i.key == "Enter") {
        if (
          this.openedPanel !== undefined &&
          Vue.prototype.$langagesAvailable[this.openedPanel].code !== null
        ) {
          this.catergoriesError = "";
          this.addCategory(
            Vue.prototype.$langagesAvailable[this.openedPanel].code
          );
        }
      }
    },
    updateSpecialities(e: any) {
      this.form.specialities = e;
    },
    updatePratiques(e: any) {
      this.form.pratiques = e;
    },
    updateCountries(e: any) {
      this.form.countries = e;
    },
    filterObjectOnSelectedLang(obj: any, toMd: boolean) {
      return Object.keys(obj)
        .filter((key) => this.selectedLang.includes(key))
        .reduce((cur, key) => {
          return Object.assign(cur, {
            [key]: toMd ? this.getMDTranslation(obj[key]) : obj[key],
          });
        }, {});
    },
    filterObjectFilesOnSelectedLang(filesImagesObj: any) {
      return Object.keys(filesImagesObj)
        .filter((key) => this.selectedLang.includes(key))
        .reduce((cur, key) => {
          return Object.assign(cur, {
            [key]: filesImagesObj[key],
          });
        }, {});
    },
    getPreparedFormToSent(): News {
      const newToAdd = new News();
      const formCopy: any = { ...this.form };

      if (this.isANewUpdate) {
        newToAdd.id = this.news?.id || null;
        newToAdd.createdAt = this.news?.createdAt || new Date();
      } else {
        newToAdd.createdAt = new Date();
      }
      newToAdd.title = this.filterObjectOnSelectedLang(formCopy.title, false);
      newToAdd.content = this.filterObjectOnSelectedLang(
        formCopy.content,
        true
      );
      newToAdd.categories = this.filterObjectOnSelectedLang(
        formCopy.categories,
        false
      );
      newToAdd.countries = this.form.countries;
      newToAdd.specialities = this.form.specialities;
      newToAdd.pratiques = this.form.pratiques;
      if (this.form.publicationDate) {
        newToAdd.publishedAt = new Date(
          this.form.publicationDate.toISOString()
        );
      }

      newToAdd.image = this.filterObjectFilesOnSelectedLang(this.form.image);
      newToAdd.countries = this.form.countries;

      return newToAdd;
    },
    async validateForm(): Promise<boolean> {
      this.specialitiesError = "";
      this.catergoriesError = "";
      this.pratiquesError = "";
      this.countriesError = "";

      let isValid = true;
      await Promise.all(
        (this.$refs.observer as any)?.map(async (element: any) => {
          if ((await element.validate()) === false) {
            isValid = false;
          }
        })
      );

      this.selectedLang.forEach((lang: string) => {
        if ((this.form.categories as any)[lang].length === 0) {
          this.catergoriesError = this.$t(
            "new.error.missingCategories"
          ).toString();
          isValid = false;
        }

        if (this.form.specialities.length === 0) {
          this.specialitiesError = this.$t("common.fieldRequired").toString();
          isValid = false;
        }

        if (this.form.pratiques.length === 0) {
          this.pratiquesError = this.$t("common.fieldRequired").toString();
          isValid = false;
        }

        if (this.form.countries.length === 0) {
          this.countriesError = this.$t("common.fieldRequired").toString();
          isValid = false;
        }
      });

      return isValid;
    },
    async validationProcess() {
      if (await this.validateForm()) {
        this.confirmDialogVisible = true;
      } else {
        this.$notify(this.$t("new.error.invalidForm").toString(), {
          color: "red",
        });
      }
    },
    sendPublicationDateLog(newsId: string) {
      if (this.form.publicationDate !== this.initialPublicationDate) {
        this.initialPublicationDate = this.form.publicationDate;
        this.$services.logs.insertNewsPublicationLog(newsId);
      }
    },
    async send() {
      this.confirmDialogVisible = false;
      this.disabled = true;
      if (this.isANewUpdate) {
        const newsForLog = this.getPreparedFormToSent();
        this.$services.news
          .updateNew(this.getPreparedFormToSent())
          .then((result: any) => {
            this.$services.logs.insertNewsUpdatedLog(
              this.getPreparedFormToSent()
            );

            result.delete_news_image.returning.forEach((file: any) => {
              this.$services.hasuraStorage.delete(file.file_id);
            });
            for (const key in newsForLog.image) {
              if ((newsForLog.image as any)[key.toString()] !== null) {
                this.$services.hasuraStorage
                  .upload((newsForLog.image as any)[key.toString()] as File)
                  .then((result: any) => {
                    this.$services.news.insertNewsImage(
                      newsForLog.id || "",
                      result.fileMetadata.id,
                      key.toString()
                    );
                  })
                  .catch(() => {
                    this.$notify(this.$t("new.error.imageError").toString(), {
                      color: "error",
                    });
                  });
              }
            }
            this.sendPublicationDateLog(newsForLog.id || "");
            this.sentDialogVisible = true;
          });
      } else {
        this.$services.news
          .insertNew(this.getPreparedFormToSent())
          .then((result: any) => {
            const newsForLog = this.getPreparedFormToSent();
            newsForLog.id = result.insert_news.returning[0].id;
            this.$services.logs.insertNewsCreationLog(newsForLog);
            this.sendPublicationDateLog(newsForLog.id || "");
            for (const key in newsForLog.image) {
              if ((newsForLog.image as any)[key.toString()] !== null) {
                this.$services.hasuraStorage
                  .upload((newsForLog.image as any)[key])
                  .then((result: any) => {
                    this.$services.news.insertNewsImage(
                      newsForLog.id || "",
                      result.fileMetadata.id,
                      key.toString()
                    );
                  });
              }
            }

            this.sentDialogVisible = true;
          });
      }

      this.disabled = false;
    },
    getMDTranslation(html: any) {
      return NodeHtmlMarkdown.translate(html, {}, undefined, undefined);
    },
    sentDialogClose() {
      this.sentDialogVisible = false;
      this.$router.push("/news");
    },
    async selectFile(file: any, lang: any) {
      this.createObjectURL(file).then((result: string) => {
        (this.images as any)[lang.code] = result;
      });
    },
    async createObjectURL(file: File): Promise<string> {
      function resizeImage(fileP: File): Promise<string> {
        return new Promise((resolve, reject) => {
          const canvas = document.createElement("canvas");
          const context = canvas.getContext("2d");
          const maxW = 150;
          const maxH = 150;
          const img = document.createElement("img");

          img.onload = function() {
            const iw = img.width;
            const ih = img.height;
            const scale = Math.min(maxW / iw, maxH / ih);
            const iwScaled = iw * scale;
            const ihScaled = ih * scale;
            canvas.width = iwScaled;
            canvas.height = ihScaled;
            context?.drawImage(img, 0, 0, iwScaled, ihScaled);
            resolve(canvas.toDataURL());
          };
          img.onerror = function(error) {
            reject(error);
          };
          img.src = URL.createObjectURL(fileP);
        });
      }

      if (file) {
        return resizeImage(file);
      }
      return "";
    },
  },
});
