import apolloProvider from "@/plugins/apollo";
import Country from "../models/country";
import { GET_COUNTRIES } from "../queries/countries";

export default class CountryDAO {
  getCountries(): Promise<Array<Country>> {
    return apolloProvider.defaultClient
      .query({
        query: GET_COUNTRIES,
      })
      .then((result: any) => {
        return result;
      });
  }
}
