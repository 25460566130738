/* eslint-disable @typescript-eslint/camelcase */
import apolloProvider from "@/plugins/apollo";
import News from "../models/news";
import {
  DELETE_NEW,
  GET_NEW,
  GET_NEWS,
  INSERT_NEW,
  INSERT_NEWS_IMAGE,
  UPDATE_NEW,
} from "../queries/news";

export default class NewsDAO {
  insertNew(newToAdd: News): Promise<any> {
    return apolloProvider.defaultClient
      .query({
        query: INSERT_NEW,
        variables: {
          categories: newToAdd.categories,
          content: newToAdd.content,
          publishedAt: this.preparedDateForUTCOffset(newToAdd.publishedAt),
          title: newToAdd.title,
          tags: newToAdd.specialities
            .concat(newToAdd.pratiques)
            .map((el: any) => {
              return { tag_id: el.id };
            }),
          countries: newToAdd.countries.map((el: any) => {
            return { country_id: el };
          }),
        },
      })
      .then((result: any) => {
        if (result.data === null) {
          return Promise.reject(result.errors[0]);
        }
        return result.data;
      });
  }
  getNews(): Promise<News[]> {
    return apolloProvider.defaultClient
      .query({
        query: GET_NEWS,
      })
      .then((result: any) => {
        if (result.data === null) {
          return Promise.reject(result.errors[0]);
        }
        const news: News[] = result.data.news.map((n: any) => {
          return News.map(n);
        });
        return Promise.resolve(news);
      });
  }

  getNew(id: string): Promise<News | null> {
    return apolloProvider.defaultClient
      .query({
        query: GET_NEW,
        variables: {
          id: id,
        },
      })
      .then((result: any) => {
        if (result.data === null) {
          return Promise.reject(result.errors[0]);
        }
        return Promise.resolve(News.map(result.data.news[0]));
      });
  }

  deleteNew(id: string): Promise<any> {
    return apolloProvider.defaultClient
      .query({
        query: DELETE_NEW,
        variables: {
          id: id,
        },
      })
      .then((result: any) => {
        if (result.errors) {
          return Promise.reject(result.errors[0]);
        }
        return Promise.resolve();
      });
  }

  updateNew(newToUpdate: News): Promise<any> {
    return apolloProvider.defaultClient
      .query({
        query: UPDATE_NEW,
        variables: {
          id: newToUpdate.id,
          categories: newToUpdate.categories,
          content: newToUpdate.content,
          publishedAt: this.preparedDateForUTCOffset(newToUpdate.publishedAt), //TODO: Après merge avec branche fix, mettre l'heure UTC
          title: newToUpdate.title,
          newsTagObject: newToUpdate.specialities
            .concat(newToUpdate.pratiques)
            .map((el: any) => {
              return { news_id: newToUpdate.id, tag_id: el.id };
            }),
          newsCountriesObject: newToUpdate.countries.map((el: any) => {
            return { news_id: newToUpdate.id, country_id: el };
          }),
        },
      })
      .then((result: any) => {
        if (result.errors) {
          return Promise.reject(result.errors[0]);
        }
        return Promise.resolve(result.data);
      });
  }

  insertNewsImage(newsId: string, imageId: string, lang: string): Promise<any> {
    return apolloProvider.defaultClient
      .query({
        query: INSERT_NEWS_IMAGE,
        variables: {
          file_id: imageId,
          news_id: newsId,
          lang: lang,
        },
      })
      .then((result: any) => {
        if (result.data === null) {
          return Promise.reject(result.errors[0]);
        }
        return result.data;
      });
  }

  preparedDateForUTCOffset(date: Date | null): Date | null {
    if(!date){
      return null;
    }
    return new Date(date.getTime() - (date.getTimezoneOffset() * 60000))

  }
}
