import apolloProvider from "@/plugins/apollo";
import Firmware from "../models/firmware";
import {
  GET_FIRMWARE,
  GET_FIRMWARES,
  UPDATE_MESSAGE_AND_VERSION_STRING_FIRMWARE,
} from "../queries/firmwares";

export default class FirmwaresDAO {
  public getFirmwares(): Promise<Firmware[]> {
    return apolloProvider.defaultClient
      .query({
        query: GET_FIRMWARES,
      })
      .then((result: any) => {
        if (result.data === null) {
          return Promise.reject(result.errors[0]);
        }
        const firmwares: Firmware[] = result.data.firmware.map((f: any) => {
          return Firmware.map(f);
        });
        return Promise.resolve(firmwares);
      });
  }

  public getFirmware(version: number): Promise<Firmware | null> {
    return apolloProvider.defaultClient
      .query({
        query: GET_FIRMWARE,
        variables: {
          version: version,
        },
      })
      .then((result: any) => {
        if (result.data === null) {
          return Promise.reject(result.errors[0]);
        }
        return Promise.resolve(Firmware.map(result.data.firmware[0]));
      });
  }

  public updateMessageAndVersionString(
    version: number,
    message: string,
    versionString: string
  ): Promise<Firmware> {
    return apolloProvider.defaultClient
      .mutate({
        mutation: UPDATE_MESSAGE_AND_VERSION_STRING_FIRMWARE,
        variables: {
          version: version,
          message: message,
          versionString: versionString,
        },
      })
      .then((result: any) => {
        if (result.data === null) {
          return Promise.reject(result.errors[0]);
        }
        return Firmware.map(result);
      });
  }
}
