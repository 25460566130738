import StorageService from "@/data/services/storage-service";
import Vue from "vue";
import VueI18n, { LocaleMessages } from "vue-i18n";

Vue.use(VueI18n);

let language;

if (!StorageService.get("language")) {
  const locale = process.env.VUE_APP_I18N_LOCALE || "en";
  StorageService.set("language", locale);
  language = locale;
} else {
  language = StorageService.get("language");
}

function loadLocaleMessages(): LocaleMessages {
  const locales = require.context(
    "@/locales",
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );
  const messages: LocaleMessages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });

  return messages;
}

export default new VueI18n({
  locale: "fr",
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en",
  messages: loadLocaleMessages(),
});
