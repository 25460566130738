import {
  HasuraStorageClient,
  StorageDeleteResponse,
  StorageUploadResponse,
} from "@nhost/hasura-storage-js";

const hasuraStorage = new HasuraStorageClient({
  url: process.env.VUE_APP_HASURA_STORAGE_URL || "",
});

export default class HasuraStorageDAO {
  getPublicUrl(id: string): string {
    return hasuraStorage.getPublicUrl({
      fileId: id,
    });
  }

  upload(file: File): Promise<StorageUploadResponse> {
    return hasuraStorage.upload({
      file: file,
    });
  }

  delete(id: string): Promise<StorageDeleteResponse> {
    return hasuraStorage.delete({
      fileId: id,
    });
  }
}
