import apolloProvider from "@/plugins/apollo";
import Log from "../models/log";
import News from "../models/news";
import { INSERT_LOG } from "../queries/logs";

export default class LogsDAO {
  insertNew(logToAdd: Log) {
    return apolloProvider.defaultClient
      .query({
        query: INSERT_LOG,
        variables: {
          type: logToAdd.type,
          'user_id': logToAdd.userId,
          'on_id': logToAdd.onId,
          comment: logToAdd.comment,
        },
      })
      .then((result: any) => {
        if (result.data === null) {
          return Promise.reject(result.errors[0]);
        }
        return News.map(result.data);
      });
  }
}
