import LogsDao from "../dao/logs-dao";
import Log from "../models/log";
import TypeEvent from "@/data/enums/event-type";
import TokenHelper from "@/helpers/token-utils";
import News from "../models/news";
import Firmware from "../models/firmware";
import QS from "../models/qs";

export default class LogsService {
  private static logsDAO = new LogsDao();

  static insertLog(log: Log) {
    return this.logsDAO.insertNew(log);
  }

  static insertSignInLog(): Promise<any> {
    const log = new Log();
    log.type = TypeEvent.SIGN_IN;
    log.userId = this.getUserId();
    return this.logsDAO.insertNew(log);
  }

  static insertSignOutLog(): Promise<any> {
    const log = new Log();
    log.type = TypeEvent.SIGN_OUT;
    log.userId = this.getUserId();
    return this.logsDAO.insertNew(log);
  }

  static insertNewsDeletionLog(news: News): Promise<any> {
    const log = new Log();
    log.type = TypeEvent.DELETE_NEW;
    log.userId = this.getUserId();
    log.onId = news.id;
    return this.logsDAO.insertNew(log);
  }

  static insertNewsUpdatedLog(news: News): Promise<any> {
    const log = new Log();
    log.type = TypeEvent.UPDATE_NEW;
    log.userId = this.getUserId();
    log.onId = news.id;
    return this.logsDAO.insertNew(log);
  }

  static insertNewsCreationLog(news: News): Promise<any> {
    const log = new Log();
    log.type = TypeEvent.CREATION_NEW;
    log.userId = this.getUserId();
    log.onId = news.id;
    return this.logsDAO.insertNew(log);
  }

  static insertFirmwareUpdateVersionNumberLog(firmware: Firmware): Promise<any> {
    const log = new Log();
    log.type = TypeEvent.UPDATE_FIRMWARE_VERSION;
    log.userId = this.getUserId();
    log.onId = firmware.version?.toString() || "";
    return this.logsDAO.insertNew(log);
  }

  static insertFirmwareUpdateDescriptionLog(firmware: Firmware): Promise<any> {
    const log = new Log();
    log.type = TypeEvent.UPDATE_FIRMWARE_DESCRIPTION;
    log.userId = this.getUserId();
    log.onId = firmware.version?.toString() || "";
    return this.logsDAO.insertNew(log);
  }

  static insertFirmwareReleaseLog(firmwareVersion: string, qsIdList: Array<string>) {
    return qsIdList.forEach((qsId: string)=>{
      const log = new Log();
      log.type = TypeEvent.DEPLOY_FIRMWARE;
      log.userId = this.getUserId();
      log.onId = qsId;
      log.comment = firmwareVersion;
      this.logsDAO.insertNew(log);
    })
  }

  static insertNewsPublicationLog(newsId: string): Promise<any> {
      const log = new Log();
      log.type = TypeEvent.PLANNING_NEW;
      log.userId = this.getUserId();
      log.onId = newsId;
      return this.logsDAO.insertNew(log);
  }

  private static getUserId(): string {
    return (TokenHelper.decoded(
      localStorage.getItem("access_token")
    ) as any).id.toString();
  }
}
