export const emailRules = (email: string): boolean => {
  const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return !!email && regex.test(email);
};

export const emailValidator = (empty: string, error: string) => {
  return [(v: string) => !!v || empty, (v: string) => emailRules(v) || error];
};

export const requiredValidator = (empty: string) => {
  return [(v: string) => !!v || empty];
};

export const minValidator = (min: number, error: string) => {
  return [(v: string) => v.length >= min || error];
};
