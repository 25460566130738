import TypeUtils from "@/helpers/type-utils";

export default class Country {
  id: number | null = null;
  name: string | null = null;
 

  static map(data: any): Country {
    const object: Country = new Country();

    object.id = TypeUtils.hasNumberOrDefault(data?.id);
    object.name = TypeUtils.hasStringOrDefault(data?.name);
    
    return object;
  }
}
