import gql from "graphql-tag";

export const GET_QS_WITH_FIRMWARE_VERSION = gql`
  query GetQSWithSpecificVersion($version: Int = 3) {
    devices(where: { firmwares: { version: { _eq: $version } } }) {
      name
      id
      serial_number
      firmwares(order_by: { created_at: desc }) {
        version
        version_string
        installed
        published
      }
    }
  }
`;


export const RELEASE_QS_FIRMWARE_VERSION =  gql`
mutation updateFirmwareVersion($version: Int = 10, $ids: [String!] = "") {
  update_firmware(where: {_and: {version: {_eq: $version}, published: {_eq: false}, device_id: {_in: $ids}}}, _set: {published: true, created_at: "NOW()"}) {
    affected_rows
  }
}
`;
