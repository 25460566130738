import Vue from "vue";
import { ValidationObserver, ValidationProvider, extend, localize } from "vee-validate";
import { email, max, size } from "vee-validate/dist/rules";

extend("required", {
  validate(value) {
    return {
      required: true,
      valid: ["", null, undefined].indexOf(value) === -1,
    };
  },
  computesRequired: true,
});

extend("email", {
  ...email,
});

extend("max", {
  ...max,
});

extend("size", {
  ...size,
});

localize("fr");

localize({
  fr: {
    messages: {
      required: "Ce champ est requis",
      min: "{length} caractères minimum",
      max: "{length} caractères maximum",
      email: "Ce champ doit être une adresse mail",
      size: "Le fichier est trop lourd ({size}ko max)"
    },
  },
});
Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);
