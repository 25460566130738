import CountryDAO from "../dao/countries-dao";
import Country from "../models/country";

export default class CountryService {
  private static CountryDAO = new CountryDAO();

  static getCountries(): Promise<Array<Country>> {
    return this.CountryDAO.getCountries().then((result: any) => {
      const countries: Country[] = result.data?.countries.map((f: any) => {
        return Country.map(f);
      });

      return Promise.resolve(countries);
    });
  }
}
