
import Vue from "vue";
import Dialog from "@/components/molecules/Dialog.vue";
import News from "@/data/models/news";

export default Vue.extend({
  name: "SentDialog",
  components: {
    Dialog,
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    news: {
      type: News,
      required: true
    }
  },
  computed:{
    getContent(): string{
      if(!this.news.publishedAt){
        return this.$t('new.sentDialog.contentNewsDraft').toString();
      } else if (this.news.publishedAt > new Date()){
        return this.$t('new.sentDialog.contentNewsPublished').toString();
      }else {
        return this.$t('new.sentDialog.contentNewsSent').toString();
      }
    },
    getTitle(): string{
      if(!this.news.publishedAt){
        return this.$t('new.sentDialog.titleNewsDraft').toString();
      } else if (this.news.publishedAt > new Date()){
        return this.$t('new.sentDialog.titleNewsPublished').toString();
      }else {
        return this.$t('new.sentDialog.titleNewsSent').toString();
      }
    }
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
});
