
import Vue from "vue";
import Dialog from "@/components/molecules/Dialog.vue";
import store from "@/store";
import RoleHelper from "@/helpers/roles-utils";
import UserRole from "@/data/enums/roles";
import AuthService from "@/data/services/auth-service";

export default Vue.extend({
  name: "App",
  components: {
    Dialog,
  },
  computed: {
    isDrawerMini: {
      get(): boolean {
        return this.$store.getters.isDrawerMini;
      },
      set() {
        null;
      },
    },
  },
  data() {
    return {
      isSignOutDialogVisible: false,
      links: [
        {
          title: this.$t("drawer.links.firmwares"),
          path: "/firmwares",
          icon: "mdi-cached",
        },
        {
          title: this.$t("drawer.links.news"),
          path: "/news",
          icon: "mdi-newspaper-variant-multiple",
        },
      ].filter((r) => {
        return !RoleHelper.disallowedRoutes(AuthService.getUser().role as UserRole).includes(r.path);
      })
,
    };
  },
  methods: {
    onInput(event: boolean) {
      this.$store.commit("setDrawerMini", event);
    },
    signOut() {
      this.isSignOutDialogVisible = false;
      this.$services.logs.insertSignOutLog();
      this.$services.auth.signOut().then(() => {
        store.commit("setCurrentUser", null);
        this.$router.replace("/");
      });
    },
  },
});
