import Vue from "vue";
import Vuex from "vuex";
import mutations from "./mutations";
import getters from "./getters";
import actions from "./actions";
import State from "./state";

Vue.use(Vuex);

export default new Vuex.Store<State>({
  state: {
    isApplicationReady: true,
    isDrawerMini: false,
    currentUser: null,
    users: [],
  },
  mutations: mutations,
  getters: getters,
  actions: actions,
});
