import TokenHelper from "@/helpers/token-utils";
import apolloProvider from "@/plugins/apollo";
import { SIGN_IN, REFRESH_TOKEN } from "../queries/auth";

export default class AuthDAO {
  public signIn(email: string, password: string): Promise<any> {
    return apolloProvider.defaultClient
      .query({
        query: SIGN_IN,
        variables: {
          username: email,
          password: password,
        },
      })
      .then((result: any) => {
        if (result.data === null) {
          return Promise.reject(result.errors[0]);
        }

        return Promise.resolve(result);
      });
  }

  public isSignedIn(): Promise<boolean> {
    const token = localStorage.getItem("access_token");
    if (token && !TokenHelper.isExpired(token)) {
      return Promise.resolve(true);
    } else if (token && TokenHelper.isExpired(token)) {
      return this.refreshToken(token).then((result: any) => {
        if (result.data === null) {
          return Promise.resolve(false);
        }
        localStorage.setItem("access_token", result.data.refreshToken.token);
        return Promise.resolve(true);
      });
    } else {
      localStorage.removeItem("access_token");
      return Promise.resolve(false);
    }
  }

  public signOut(): Promise<any> {
    return Promise.resolve(localStorage.removeItem("access_token"));
  }

  public refreshToken(token: string) {
    return apolloProvider.defaultClient
      .query({
        query: REFRESH_TOKEN,
        variables: {
          refreshToken: token,
        },
      })
      .then((result: any) => {
        if (result.data === null) {
          return Promise.reject(result.errors[0]);
        }

        return Promise.resolve(result);
      });
  }
}
