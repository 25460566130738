import TypeUtils from "@/helpers/type-utils";

export default class Tag {
  id: string | null = null;
  name: object | null = null;
  type: string | null = null;

  static map(data: any): Tag {
    const object: Tag = new Tag();

    object.id = TypeUtils.hasStringOrDefault(data?.id);
    object.name = TypeUtils.hasObjectOrDefault(data?.name);
    object.type = TypeUtils.hasStringOrDefault(data?.type);
    
    return object;
  }
}
