import apolloProvider from "@/plugins/apollo";
import Tag from "../models/tag";
import { GET_TAGS_WITH_SPECIFIC_TYPE } from "../queries/tags";


export default class TagDAO {
  public getTagsWithSpecificType(type: string): Promise<Tag[]> {
    return apolloProvider.defaultClient
      .query({
        query: GET_TAGS_WITH_SPECIFIC_TYPE,
        variables: {
          type: type,
        },
      })
      .then((result: any) => {
        if (result.data === null) {
          return Promise.reject(result.errors[0]);
        }
        const tags: Tag[] = result.data.tags.map((d: any) => {
          return Tag.map(d);
        });
        return tags;
      });
  }
}
