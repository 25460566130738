
import Vue from "vue";
import Dialog from "@/components/molecules/Dialog.vue";
import News from "@/data/models/news";

export default Vue.extend({
  name: "ConfirmDialog",
  components: {
    Dialog,
  },
  computed: {
    getTitle(): string {
        if(!this.news.publishedAt){
            return this.$t('new.confirmDialog.draftTitle').toString();
        } else if (this.news.publishedAt > new Date()){
            return this.$t('new.confirmDialog.programTitle').toString();
        } else {
            return this.$t('new.confirmDialog.sendTitle').toString();
        }
    },
    getContent(): string {
        if(!this.news.publishedAt){
            return this.$t('new.confirmDialog.draftContent').toString();
        } else if (this.news.publishedAt > new Date()){
            return this.$t('new.confirmDialog.programContent', {date: this.news.publishedAt.toLocaleString()}).toString();
        } else {
            return this.$t('new.confirmDialog.sendContent').toString();
        }
    },
    getButtonLabel(): string {
        if(!this.news.publishedAt){
            return this.$t('new.confirmDialog.draftButton').toString();
        } else if (this.news.publishedAt > new Date()){
            return this.$t('new.confirmDialog.programButton').toString();
        } else {
            return this.$t('new.confirmDialog.sendButton').toString();
        }
    }
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    news: {
      type: News,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
    next() {
      this.$emit("next");
    },
  },
});
