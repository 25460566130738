import TypeUtils from "@/helpers/type-utils";
import Country from "./country";
import TagType from "../enums/tag-type";
import Tag from "./tag";
import i18n from "@/plugins/i18n";

export default class News {
  id: string | null = null;
  title: object | null = null;
  createdAt: Date | null = null;
  publishedAt: Date | null = null;
  categories: object | null = null;
  content: object | null = null;
  countries: number[] = [];
  specialities: Tag[] = [];
  pratiques: Tag[] = [];
  image: object | null = null;

  static map(data: any): News {
    const object: News = new News();

    object.id = TypeUtils.hasStringOrDefault(data?.id);
    object.title = TypeUtils.hasObjectOrDefault(data?.title);
    object.createdAt = TypeUtils.hasDateOrDefault(data?.created_at);
    object.publishedAt = TypeUtils.hasDateOrDefault(data?.published_at);
    object.categories = TypeUtils.hasObjectOrDefault(data?.categories);
    object.content = TypeUtils.hasObjectOrDefault(data?.content);
    object.image = TypeUtils.hasObjectOrDefault(data?.news_images);

    data?.news_countries &&
      data.news_countries.forEach((c: any) => {
        object.countries.push(Country.map(c.country).id || -1);
      });

    data?.news_tags &&
      data.news_tags
        .filter((tag: any) => {
          return tag.tag.type === TagType.SPECIALITY;
        })
        .forEach((tag: any) => {
          object.specialities.push(Tag.map(tag.tag));
        });

    data?.news_tags &&
      data.news_tags
        .filter((tag: any) => {
          return tag.tag.type === TagType.DENTIST_STATUS;
        })
        .forEach((tag: any) => {
          object.pratiques.push(Tag.map(tag.tag));
        });
    return object;
  }

  get statut(): string {
    if (!this.publishedAt) {
      return i18n.t("news.table.content.statut.draft").toString();
    } else if (this.publishedAt.getTime() < Date.now()) {
      return i18n.t("news.table.content.statut.sent").toString();
    } else {
      return i18n.t("news.table.content.statut.programmed").toString();
    }
  }

  get titleString(): string {
    return (
      (this.title as any)?.fr ||
      (this.title as any)?.en ||
      (this.title as any)?.de ||
      ""
    );
  }
}
