
import Vue from "vue";
import Drawer from "@/components/organisms/Drawer.vue";
import Header from "@/components/molecules/Header.vue";

export default Vue.extend({
  components: { Drawer, Header },
  name: "App",
  metaInfo() {
    const title = this.$t(this.$route.meta?.title).toString();
    const appTitle = this.$t("common.appTitle").toString();
    return {
      title: `${title} | ${appTitle}`,
    };
  },
  computed: {
    showDrawer(): boolean {
      return this.$route.meta.showDrawer || false;
    },
    showHeader(): boolean {
      return this.$route.meta.showHeader || false;
    },
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
  },
  data() {
    return {
      drawer: false,
    };
  },
});
