import gql from "graphql-tag";

export const GET_NEWS = gql`
  query getNews {
    news(order_by: { created_at: desc }) {
      id
      content
      categories
      created_at
      published_at
      title
      news_countries {
        country {
          id
          name
        }
      }
      news_tags {
        tag {
          id
          name
          type
        }
      }
    }
  }
`;

export const GET_NEW = gql`
  query getNew($id: uuid = "") {
    news(where: { id: { _eq: $id } }) {
      id
      content
      categories
      created_at
      published_at
      title
      news_countries {
        country {
          id
          name
        }
      }
      news_tags {
        tag {
          id
          name
          type
        }
      }
      news_images {
        file_id
        lang
      }
    }
  }
`;

export const INSERT_NEW = gql`
  mutation insertNew(
    $categories: jsonb = ""
    $content: jsonb = ""
    $publishedAt: timestamp = ""
    $title: jsonb = ""
    $tags: [news_tag_insert_input!]!
    $countries: [news_countries_insert_input!]!
  ) {
    insert_news(
      objects: {
        categories: $categories
        content: $content
        published_at: $publishedAt
        title: $title
        news_tags: { data: $tags }
        news_countries: { data: $countries }
      }
    ) {
      returning {
        id
      }
    }
  }
`;

export const DELETE_NEW = gql`
  mutation deleteNew($id: uuid = "") {
    delete_news_by_pk(id: $id) {
      title
    }
  }
`;

export const UPDATE_NEW = gql`
  mutation updateNews(
    $id: uuid = ""
    $categories: jsonb = ""
    $content: jsonb = ""
    $publishedAt: timestamp = ""
    $title: jsonb = ""
    $newsTagObject: [news_tag_insert_input!]!
    $newsCountriesObject: [news_countries_insert_input!]!
  ) {
    update_news_by_pk(
      pk_columns: { id: $id }
      _set: {
        categories: $categories
        content: $content
        published_at: $publishedAt
        title: $title
      }
    ) {
      id
    }
    delete_news_countries(where: { news_id: { _eq: $id } }) {
      affected_rows
    }
    insert_news_countries(objects: $newsCountriesObject) {
      affected_rows
    }
    delete_news_tag(where: { news_id: { _eq: $id } }) {
      affected_rows
    }
    insert_news_tag(objects: $newsTagObject) {
      affected_rows
    }
    delete_news_image(where: { news_id: { _eq: $id } }) {
      returning {
        file_id
      }
    }
  }
`;

export const INSERT_NEWS_IMAGE = gql`
  mutation insertNewsImages(
    $file_id: uuid = ""
    $news_id: uuid = ""
    $lang: String = ""
  ) {
    insert_news_image(
      objects: { file_id: $file_id, news_id: $news_id, lang: $lang }
    ) {
      affected_rows
    }
  }
`;
