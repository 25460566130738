import Vue from "vue";
import App from "@/App.vue";
import router from "@/router";
import store from "@/store";
import vuetify from "@/plugins/vuetify";
import i18n from "@/plugins/i18n";
import { services } from "@/data/di";
import "@/plugins/vue-meta";
import "@/plugins/vue-clipboard2";
import "@/plugins/vee-validate";
import "@/index.css";
import "@/scss/index.scss";
import ViewContainer from "@/components/atoms/ViewContainer.vue";
import Button from "@/components/atoms/Button.vue";
import Input from "@/components/atoms/Input.vue";
import Header from "@/components/molecules/Header.vue";
import CheckBox from "@/components/atoms/CheckBox.vue";
import Select from "@/components/atoms/Select.vue";
import { VueEditor } from "vue2-editor";
import DateTimePickerDialog from '@/components/atoms/DateTimePicker.vue';
Vue.config.productionTip = false;

// Global components
Vue.component("ViewContainer", ViewContainer);
Vue.component("Button", Button);
Vue.component("Input", Input);
Vue.component("Header", Header);
Vue.component("CheckBox",CheckBox);
Vue.component("Select",Select);
Vue.component("TextEditor", VueEditor);
Vue.component("DateTimePickerDialog",DateTimePickerDialog)

// Global var
Vue.prototype.$langagesAvailable = [{ label: "France", code: "fr", lang: "Français" }, {label: "English", code: "en", lang: "Anglais"}, {label: "Deutsch", code: "de", lang: "Allemand"}];

// Dependancy Injection
Vue.prototype.$services = services;

export const bus = new Vue();

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
