import TypeUtils from "@/helpers/type-utils";

export default class Firmware {
  version: number | null = null;
  versionString: string | null = null;
  publicationDate: Date | null = null;
  targetedQS: number | null = null;
  eligibleQS: number | null = null;
  installedQS: number | null = null;
  message: object | null = null;

  static map(data: any): Firmware {
    const object: Firmware = new Firmware();

    object.version = TypeUtils.hasNumberOrDefault(data?.version);
    object.versionString = TypeUtils.hasStringOrDefault(data?.version_string);
    object.message = TypeUtils.hasObjectOrDefault(data?.message);
    object.publicationDate = TypeUtils.hasDateOrDefault(data?.created_at, new Date());
    object.targetedQS = TypeUtils.hasNumberOrDefault(data?.targeted_qs);
    object.eligibleQS = TypeUtils.hasNumberOrDefault(data?.eligible_qs);
    object.installedQS = TypeUtils.hasNumberOrDefault(data?.installed_qs);
    return object;
  }
}
