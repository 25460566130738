import JWT_DECODE from "jwt-decode";

export default class TokenHelper {
  static isExpired(token: string | null) {
    if (token && JWT_DECODE(token)) {
      const expiry = (JWT_DECODE(token) as any).exp;
      const now = new Date();
      return now.getTime() > expiry * 1000;
    }
    return false;

  }

  static decoded(token: string | null) {
    if (token) {
      return JWT_DECODE(token);
    }
    return {};
  }
}
