import FirmwaresDAO from "../dao/firmwares-dao";
import Firmware from "../models/firmware";

export default class FirmwaresService {
  private static FirmwaresDAO = new FirmwaresDAO();

  static getFirmwares(): Promise<Array<Firmware>> {
    return this.FirmwaresDAO.getFirmwares();
  }

  static getFirmware(id: number): Promise<Firmware | null> {
    return this.FirmwaresDAO.getFirmware(id);
  }

  static updateMessageAndVersionString(
    version: number,
    message: any,
    versionString: string
  ) {
    return this.FirmwaresDAO.updateMessageAndVersionString(
      version,
      message,
      versionString
    );
  }
}
