import TypeUtils from "@/helpers/type-utils";
import Firmware from "./firmware";

export default class QS {
  id: string | null = null;
  name: string | null = null;
  serialNumber: string | null = null;
  actualFirmware: any | null = null;
  pusblishedFirmwares: Array<Firmware> | null = null;
  installedFirmwares: Array<Firmware> | null = null;

  static map(data: any): QS {
    const object: QS = new QS();
    
    object.id = TypeUtils.hasStringOrDefault(data?.id);
    object.name = TypeUtils.hasStringOrDefault(data?.name);
    object.serialNumber = TypeUtils.hasStringOrDefault(data?.serial_number);
    object.actualFirmware = TypeUtils.hasObjectOrDefault(
      (data?.firmwares).sort((a: any,b: any)=>{
        return a.version > b.version;
      }).find((element: any) => element.installed === true)
    );
    object.pusblishedFirmwares = TypeUtils.hasArrayOrDefault(
      data?.firmwares.filter((element: any) => element.published === true)
    );
    object.installedFirmwares = TypeUtils.hasArrayOrDefault(
      data?.firmwares.filter((element: any) => element.installed === true)
    );
    
    return object;
  }

  isPublished(firmware: Firmware) {
    return (
      this.pusblishedFirmwares?.filter((element: any) => {
        return (
          element.version === firmware.version && element.published === true
        );
      }).length !== 0
    );
  }

  isInstalled(firmware: Firmware) {
    return (
      this.installedFirmwares?.filter((element: any) => {
        return (
          element.version === firmware.version && element.installed === true
        );
      }).length !== 0
    );
  }

  hasABetterVersionThan(firmware: Firmware){
    if(firmware.version && this.actualFirmware?.version){
      return this.actualFirmware.version > firmware.version;
    }
    return false;
  }
}
