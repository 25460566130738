import State from "./state";

export default {
  isApplicationReady(state: State) {
    return state.isApplicationReady;
  },
  isDrawerMini(state: State) {
    return state.isDrawerMini;
  },
  currentUser(state: State) {
    return state.currentUser;
  },
  users(state: State) {
    return state.users;
  },
};
