import gql from "graphql-tag";

export const SIGN_IN = gql`
  query SignIn($password: String = "", $username: String = "") {
    login(loginInput: { password: $password, username: $username }) {
      token
    }
  }
`;

export const REFRESH_TOKEN = gql`
  query RefreshToken($refreshToken: String = "") {
    refreshToken(refreshToken: $refreshToken) {
      token
    }
  }
`;
