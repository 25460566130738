
import Vue from "vue";
import { emailValidator, requiredValidator } from "@/helpers/rules-validator";
import Dialog from "@/components/molecules/Dialog.vue";
import Input from "@/components/atoms/Input.vue";
import Button from "@/components/atoms/Button.vue";
import User from "@/data/models/user";

import TokenHelper from "@/helpers/token-utils";
import store from "@/store";
import RoleHelper from "@/helpers/roles-utils";
import UserRole from "@/data/enums/roles";

export default Vue.extend({
  components: { Dialog, Input, Button },
  name: "Auth",
  metaInfo() {
    return {
      title: this.$t("auth.title").toString(),
    };
  },
  data() {
    return {
      email: "",
      emailRules: emailValidator(
        this.$t("validators.emailRequired").toString(),
        this.$t("validators.emailBadlyFormatted").toString()
      ),
      password: "",
      valid: false,
      loadingAuth: false,
      showDialog: false,
      dialogTitle: "",
      dialogMessage: "",
      showPassword: false,
      errorType: "",
    };
  },
  computed: {
    containerBackgroundColor() {
      const theme = this.$vuetify.theme.dark ? "dark" : "light";
      const color = (this.$vuetify.theme.themes[theme] as any).authBackground;
      return `background-color: ${color} !important`;
    },
    form(): Vue & { validate: () => boolean; resetValidation: () => void } {
      return this.$refs.form as Vue & {
        validate: () => boolean;
        resetValidation: () => void;
      };
    },
    passwordRules() {
      return requiredValidator(
        this.$t("validators.passwordRequired").toString()
      );
    },
    getImageHeight(): string {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
          return "300px";
        case "lg":
          return "150px";
        default:
          return "0px";
      }
    },
    isXLorLG(): boolean {
      return (
        this.$vuetify.breakpoint.name === "xl" ||
        this.$vuetify.breakpoint.name === "lg"
      );
    },
  },
  methods: {
    auth() {
      if (!this.form.validate()) {
        return;
      }

      this.loadingAuth = true;

      this.$services.auth
        .signIn(this.email, this.password)
        .then((result: any) => {
          const userLogged = User.map(
            TokenHelper.decoded(result.data.login.token) as any
          );
          if (userLogged.isAllowed()) {
            localStorage.setItem("access_token", result.data.login.token);
            this.$services.logs.insertSignInLog();
            store.commit("setCurrentUser", userLogged);
            this.handleNext();
          } else {
            this.dialogTitle = this.$t(
              "auth.errors.titleNotAuthorized"
            ).toString();
            this.dialogMessage = this.$t(
              "auth.errors.contentNotAuthorized"
            ).toString();
            this.showDialog = true;
          }
        })
        .catch(() => {
          this.dialogTitle = this.$t(
            "auth.errors.titleWrongCredentials"
          ).toString();
          this.dialogMessage = this.$t(
            "auth.errors.contentWrongCredentials"
          ).toString();
          this.showDialog = true;
        })
        .finally(() => (this.loadingAuth = false));
    },
    handleNext() {
      const next = RoleHelper.redirect(this.$store.getters.currentUser.role as UserRole);
      this.$router.push(next);
    },
  },
});
