import State from "./state";
import User from "@/data/models/user";

export default {
  setCurrentUser(state: State, currentUser: User | null) {
    state.currentUser = currentUser;
  },
  setApplicationReady(state: State) {
    state.isApplicationReady = true;
  },
  setDrawerMini(state: State, mini: boolean) {
    state.isDrawerMini = mini;
  },
  setUsers(state: State, users: User[]) {
    state.users = users;
  },
};
