
import Firmware from "@/data/models/firmware";
import Vue from "vue";

export default Vue.extend({
  components: {},
  name: "Firmwares",
  data() {
    return {
      firmwares: [] as Firmware[],
      loading: true,
    };
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t("firmwares.table.headers.version"),
          align: "start",
          sortable: true,
          value: "version",
          class: "black--text",
        },
        {
          text: this.$t("firmwares.table.headers.versionString"),
          sortable: true,
          value: "versionString",
          class: "black--text",
        },
        {
          text: this.$t("firmwares.table.headers.addedDate"),
          sortable: true,
          value: "publicationDate",
          class: "black--text",
        },
        {
          text: this.$t("firmwares.table.headers.statut"),
          sortable: true,
          value: "statut",
          class: "black--text",
        },
        {
          text: this.$t("firmwares.table.headers.eligibleQS"),
          sortable: true,
          value: "eligibleQS",
          class: "black--text",
        },
        {
          text: this.$t("firmwares.table.headers.targetedQS"),
          sortable: true,
          value: "targetedQS",
          class: "black--text",
        },
        {
          text: this.$t("firmwares.table.headers.installation"),
          sortable: true,
          value: "installedQS",
          class: "black--text",
        },
        { text: "", value: "actions", sortable: false, align: "end" },
      ];
    },
  },
  mounted() {
    this.$services.firmwares.getFirmwares().then((data: Firmware[]) => {
      this.firmwares = data;
      this.loading = false;
    });
  },
  methods: {
    moreDetails(firmware: Firmware) {
      this.$router.push(`/firmwares/${firmware.version}`);
    },
  },
});
