import TypeUtils from "@/helpers/type-utils";
import EventType from "../enums/event-type";

export default class Log {
  id: string | null = null;
  type: string | null = null;
  date: Date | null = null;
  userId: string | null = null;
  onId: string | null = null;
  comment: string | null = null;

  static map(data: any): Log {
    const object: Log = new Log();

    object.id = TypeUtils.hasStringOrDefault(data?.id);
    object.type = TypeUtils.hasStringOrDefault(data?.type);
    object.userId = TypeUtils.hasStringOrDefault(data?.origin_id);
    object.date = TypeUtils.hasDateOrDefault(data?.date, new Date());
    object.onId = TypeUtils.hasStringOrDefault(data?.on_id); 
    object.comment = TypeUtils.hasStringOrDefault(data?.comment);

    return object;
  }
}
