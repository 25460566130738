enum EventType{
    SIGN_IN = "signIn",
    SIGN_OUT = "signOut",
    UPDATE_FIRMWARE_VERSION = "firmwareVersionUpdate",
    UPDATE_FIRMWARE_DESCRIPTION = "firmwareDescriptionUpdate",
    DEPLOY_FIRMWARE = "firmwareDeploy",
    CREATION_NEW= "newsCreation",
    UPDATE_NEW = "newsUpdate",
    DELETE_NEW = "newsDeletion",
    PLANNING_NEW = "newsPlanification"
} 

export default EventType;