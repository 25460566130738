import Auth from "@/views/Auth.vue";
import NotFound from "@/views/NotFound.vue";
import { RouteConfig } from "vue-router";

const publicRoutes: Array<RouteConfig> = [
  {
    path: "/",
    component: Auth,
    meta: {
      isSecured: false,
      title: "auth.title",
    },
  },
  {
    path: "/404",
    component: NotFound,
    meta: { title: "404.title", isSecured: false, showHeader: false },
  },
  { path: "/:pathMatch(.*)*", redirect: "/404" },
];

export default publicRoutes;
