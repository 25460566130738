
import Vue, { PropType } from "vue";

export default Vue.extend({
  name: "Select",
  data() {
    return {
      searchTerm: "",
    };
  },
  props: {
    items: {
      type: Array as PropType<any>,
      default: () => [],
    },
    value: {},
    itemText: {
      type: String,
    },
    itemValue: {},
    id: {
      type: String,
    },
    name: {
      type: String,
    },
    autocomplete: {
      type: String,
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
    },
    prependIcon: {
      type: String,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    filteredField: {
      type: String,
      default: "",
    },
    searchPlaceholder: {
      type: String,
      default: "",
    },
  },
  computed: {
    content: {
      get(): any {
        return this.value;
      },
      set(newValue: any) {
        this.$emit("input", newValue);
      },
    },
  },
  methods: {
    updateValue(e: InputEvent) {
      this.$emit("input", e);
    },
    getFileteredItems() {
      if (this.searchTerm !== "") {
        return this.items.filter((item: any) => {
          return (
            item[`${this.filteredField}`]
              .toLowerCase()
              .indexOf(this.searchTerm.toLowerCase()) > -1
          );
        });
      }
      return this.items;
    },
    resetSearchTerm(){
      setTimeout(() => {
        this.searchTerm='';
    }, 120)
    }
  },
});
