
import Vue from "vue";

export default Vue.extend({
  name: "Header",
  props: {
    customTitle: {
      type: String,
    },
  },
  computed: {
    title(): string {
      if (this.customTitle) {
        return this.customTitle;
      }

      return this.$route.meta?.title
        ? this.$t(this.$route.meta.title).toString()
        : "";
    },
  },
});
