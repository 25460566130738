import Vue from "vue";
import VueRouter, { NavigationGuardNext, Route } from "vue-router";
import privateRoutes from "./routes/private";
import publicRoutes from "./routes/public";
import store from "@/store";
import AuthService from "@/data/services/auth-service";
import Meta from "vue-meta";
import RoleHelper from "@/helpers/roles-utils";
import UserRole from "@/data/enums/roles";

Vue.use(VueRouter);
Vue.use(Meta);

const handleRoute = async (to: Route, _from: Route, next: NavigationGuardNext) => {
  if (to.meta?.isSecured && !(await AuthService.isSignedIn)) {
    next({ path: "/", replace: true }); 
  } else if (to.path === "/" && await AuthService.isSignedIn) {
    next({ path: "/firmwares", replace: true });
  } else {
    if (RoleHelper.disallowedRoutes(AuthService.getUser().role as UserRole).includes(to.path || "")) {
      next(RoleHelper.redirect(AuthService.getUser().role as UserRole));
      return;
    }
    next();
  }
};

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [...privateRoutes, ...publicRoutes],
});

router.beforeEach((to, from, next) => {
  if (store.getters.isApplicationReady) {
    handleRoute(to, from, next);
  } else {
    store.watch(
      (state) => state.isApplicationReady,
      (ready) => {
        if (ready) {
          handleRoute(to, from, next);
        }
      }
    );
  }
});

export default router;
