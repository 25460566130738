
import Vue from "vue";
import News from "@/data/models/news";
import DeleteNewsDialog from "@/components/organisms/news/DeleteNewsDialog.vue";

export default Vue.extend({
  components: { DeleteNewsDialog },
  name: "News",
  data() {
    return {
      news: [] as News[],
      loading: true,
      deleteNewsDialogVisible: false,
      selectedNews: (null as unknown) as News,
    };
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t("news.table.headers.title"),
          align: "start",
          sortable: true,
          value: "title",
          class: "black--text",
          sort: (a: any, b: any) => {
            return (a.fr || a.en || a.de).localeCompare(b.fr || b.en || b.de);
          },
        },
        {
          text: this.$t("news.table.headers.statut"),
          sortable: true,
          value: "statut",
          class: "black--text",
        },
        {
          text: this.$t("news.table.headers.categories"),
          sortable: true,
          value: "categories",
          class: "black--text",
          sort: (a: any, b: any) => {
            return (this.getItemCategoriesToString({"categories":a})).localeCompare(this.getItemCategoriesToString({"categories":b}));
          },
        },
        {
          text: this.$t("news.table.headers.publicationDate"),
          sortable: true,
          value: "publishedAt",
          class: "black--text",
        },
        {
          text: "",
          class: "black--text",
          value: "actions",
          sortable: false,
          align: "end",
        },
      ];
    },
  },
  mounted() {
    this.$services.news.getNews().then((data: News[]) => {
      this.news = data;
      this.loading = false;
    });
  },
  methods: {
    moreDetails(item: News) {
      this.$router.push(`/news/${item.id}`);
    },
    getStatutColor(news: News): string {
      if (!news.publishedAt) {
        return "red";
      } else if (news.publishedAt.getTime() < Date.now()) {
        return "green";
      } else {
        return "blue";
      }
    },
    openDeleteDialog(item: News) {
      this.selectedNews = item;
      this.deleteNewsDialogVisible = true;
    },
    reload() {
      this.$services.news.getNews().then((data: News[]) => {
        this.news = data;
        this.loading = false;
      });
    },
    redirect() {
      this.$router.push("/news/create");
    },
    getItemCategoriesToString(item: any): string {
      if (item.categories.fr) {
        return item.categories.fr.join(", ");
      } else if (item.categories.en) {
        return item.categories.en.join(", ");
      } else if (item.categories.de) {
        return item.categories.de.join(", ");
      }
      return "";
    },
  },
});
