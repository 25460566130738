import { Store } from "vuex";
import AuthService from "@/data/services/auth-service";
import UsersService from "@/data/services/users-service";
import StorageService from "@/data/services/storage-service";
import FirmwaresService from "../services/firmwares-service";
import QsService from "../services/qs-service";
import NewsService from "@/data/services/news-services";
import CountryService from "../services/countries-service";
import TagsService from "../services/tags-services";
import LogsService from "../services/logs-service";
import HasuraStorageService from "../services/hasura-storage-service";

export const services = {
  users: UsersService,
  auth: AuthService,
  storage: StorageService,
  firmwares: FirmwaresService,
  qs: QsService,
  news: NewsService,
  countries: CountryService,
  tags: TagsService,
  logs: LogsService,
  hasuraStorage: HasuraStorageService
};

declare module "vue/types/vue" {
  interface Vue {
    $services: {
      users: typeof UsersService;
      auth: typeof AuthService;
      storage: typeof StorageService;
      firmwares: typeof FirmwaresService;
      qs: typeof QsService;
      news: typeof NewsService;
      countries: typeof CountryService;
      tags: typeof TagsService;
      logs: typeof LogsService;
      hasuraStorage: typeof HasuraStorageService;
    };
    $store: Store<any>;
  }
}
