import NewsDAO from "../dao/news-dao";
import News from "../models/news";

export default class NewsService {
  private static newsDao = new NewsDAO();

  static getNews(): Promise<Array<News>> {
    return this.newsDao.getNews();
  }

  static getNew(id: string): Promise<News | null> {
    return this.newsDao.getNew(id);
  }

  static insertNew(newToAdd: News): Promise<any> {
    return this.newsDao.insertNew(newToAdd);
  }

  static deleteNew(id: string): Promise<any> {
    return this.newsDao.deleteNew(id);
  }

  static updateNew(newToUpdate: News): Promise<any> {
    return this.newsDao.updateNew(newToUpdate);
  }

  static insertNewsImage(newsId: string, imageId: string, lang: string): Promise<any> {
    return this.newsDao.insertNewsImage(newsId,imageId, lang);
  }
}
