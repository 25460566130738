import TagDAO from "../dao/tag-dao";
import Tag from "../models/tag";

export default class TagsService {
  private static tagsDao = new TagDAO();

  static getTagsWithSpecificType(type: string): Promise<Tag[]> {
    return this.tagsDao.getTagsWithSpecificType(type);
  }

}
