import VueApollo from "vue-apollo";
import { ApolloClient, DefaultOptions } from "apollo-client";
import { createHttpLink, HttpLink } from "apollo-link-http";
import { InMemoryCache } from "apollo-cache-inmemory";
import { setContext } from "apollo-link-context";
import TokenHelper from "@/helpers/token-utils";
import AuthService from "@/data/services/auth-service";
import RoleHelper from "@/helpers/roles-utils";

const httpLink = createHttpLink({
  uri: process.env.VUE_APP_HASURA_GRAPHQL_URL,
} as HttpLink.Options);

const authLink = setContext((_, { headers }) => {
  let token = localStorage.getItem("access_token");
  if (TokenHelper.isExpired(token)) {
    AuthService.refreshToken(token || "")
      .then((resultRefresh: any) => {
        if (resultRefresh.data.refreshToken.token !== null) {
          localStorage.setItem(
            "access_token",
            resultRefresh.data.refreshToken.token
          );
        } else {
          AuthService.signOut();
          window.location.reload();
        }
      })
      .catch(() => {
        AuthService.signOut();
        window.location.reload();
      });
  }

  token = localStorage.getItem("access_token");
  if(token){
    const roles = (TokenHelper.decoded(token) as any)?.["https://hasura.io/jwt/claims"]?.["x-hasura-allowed-roles"];
    return {
      headers: {
        ...headers,
        "content-type": "application/json",
        "authorization": `Bearer ${token}`,
        "X-Hasura-Role" : RoleHelper.getHigherRole(roles)
      },
    };
  }
  return {
    headers: {
      ...headers,
      "content-type": "application/json",
    },
  };
});

const defaultOptions: DefaultOptions = {
  query: {
    fetchPolicy: "no-cache",
    errorPolicy: "all",
  },
};

const apolloClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
  defaultOptions: defaultOptions,
});

const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
});

export default apolloProvider;
