import apolloProvider from "@/plugins/apollo";
import QS from "../models/qs";
import {
  GET_QS_WITH_FIRMWARE_VERSION,
  RELEASE_QS_FIRMWARE_VERSION,
} from "../queries/qs";

export default class QsDAO {
  public getQSWithFirmware(version: number): Promise<QS[]> {
    return apolloProvider.defaultClient
      .query({
        query: GET_QS_WITH_FIRMWARE_VERSION,
        variables: {
          version: version,
        },
      })
      .then((result: any) => {
        if (result.data === null) {
          return Promise.reject(result.errors[0]);
        }
        const qs: QS[] = result.data.devices.map((d: any) => {
          return QS.map(d);
        });
        return Promise.resolve(qs);
      });
  }

  public updateQSReleasdFirmware(version: number, ids: string[]): Promise<any> {
    return apolloProvider.defaultClient
      .mutate({
        mutation: RELEASE_QS_FIRMWARE_VERSION,
        variables: {
          version: version,
          ids: ids,
        },
      })
      .then((result: any) => {
        if (result.data === null) {
          return Promise.reject(result.errors[0]);
        }
        return Promise.resolve(result.data.update_firmware.affected_rows);
      });
  }
}
