
import User from "@/data/models/user";

export default class UsersService {
  //private static usersDao = new UsersDao();


  /*static getUserById(id: string): Promise<User | null> {
    return this.usersDao.getUserById(id);
  }*/

  /*static updateUser(user: User): Promise<void> {
    return this.usersDao.update(user.id, user);
  }*/
}
