import Firmwares from "@/views/Firmwares.vue"
import Firmware from "@/views/Firmware.vue";
import News from "@/views/News.vue";
import New from "@/views/New.vue";

import { RouteConfig } from "vue-router";

const privateRoutes: Array<RouteConfig> = [
  {
    path: "/firmwares",
    name: "Firmwares",
    component: Firmwares,
    meta: {
      isSecured: true,
      showDrawer: true,
      showHeader: true,
      title: "firmwares.title",
    },
  },
  {
    path: "/firmwares/:id",
    name: "Firmware",
    component: Firmware,
    meta: {
      isSecured: true,
      showDrawer: true,
      showHeader: false,
      title: `firmware.title`,
    },
  },
  {
    path: "/news",
    name: "News",
    component: News,
    meta: {
      isSecured: true,
      showDrawer: true,
      showHeader: false,
      title: `news.title`,
    },
  },
  {
    path: "/news/:id",
    name: "New",
    component: New,
    meta: {
      isSecured: true,
      showDrawer: true,
      showHeader: false,
      title: `news.title`,
    },
  },
];

export default privateRoutes;
