import UserRole from "@/data/enums/roles";

export default class RoleHelper {
  static disallowedRoutes(role: UserRole): string[] {
    switch (role) {
      case UserRole.ADMIN:
        return [];
      case UserRole.TECH:
        return ["/news","/news/:id"];
      case UserRole.MARKETING:
        return [];
      default:
        return [];
    }
  }

  static redirect(role: UserRole): string {
    switch (role) {
        case UserRole.ADMIN:
          return "/firmwares";
        case UserRole.TECH:
          return "/firmwares";
        case UserRole.MARKETING:
          return "/news";
        default:
          return "/404";
      }
  }

  static getHigherRole(roles: string[]): string {
    if(!roles){
        return "";
    }
    if(roles.includes(UserRole.ADMIN)){
        return UserRole.ADMIN;
    } else if(roles.includes(UserRole.TECH)){
        return UserRole.TECH;
    } else {
        return UserRole.MARKETING;
    }
  }
}
