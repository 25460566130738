import QsDAO from "../dao/qs-dao";
import QS from "../models/qs";

export default class FirmwaresService {
  private static QsDAO = new QsDAO();

  static getQSWithFirmware(firmwareVersion: number): Promise<QS[]> {
    return this.QsDAO.getQSWithFirmware(firmwareVersion);
  }

  static updateQSReleasdFirmware(version: number, ids: string[]){
    return this.QsDAO.updateQSReleasdFirmware(version, ids);
  }
}
