import { StorageDeleteResponse, StorageUploadResponse } from "@nhost/hasura-storage-js/dist/utils/types";
import HasuraStorageDAO from "../dao/hasura-storage-dao";


export default class HasuraStorageService {
    private static HasuraStorageDAO = new HasuraStorageDAO();

    static getPublicUrl(id: string): string {
        return this.HasuraStorageDAO.getPublicUrl(id);
    }

    static upload(file: File): Promise<StorageUploadResponse> {
        return this.HasuraStorageDAO.upload(file);
    }

    static delete(id: string): Promise<StorageDeleteResponse>{
        return this.HasuraStorageDAO.delete(id);
    }
}
