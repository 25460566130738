
import Vue from "vue";
import QS from "@/data/models/qs";
import Firmware from "@/data/models/firmware";
import Dialog from "@/components/molecules/Dialog.vue";
import { bus } from "@/main";

export default Vue.extend({
  components: { Dialog },
  name: "QSList",
  data() {
    return {
      qsSelected: [] as QS[],
      qsLinked: [] as QS[],
      loading: true,
      firmware: {} as Firmware,
      selectAllBoolean: false,
      keyPressed: false,
      releaseUpdateDialogVisible: false,
      updateReleasedSuccessfullyDialogVisible: false,
      updateReleaseErrorDialogVisible: false,
      errorInformationDialog: {
        visible: false,
        content: "",
      },
      selectableItems: 0,
      informationForm: {} as any,
    };
  },
  mounted() {
    this.$services.firmwares
      .getFirmware(Number(this.$route.params.id))
      .then((data: any) => {
        this.firmware = data;
      });
    this.$services.qs
      .getQSWithFirmware(Number(this.$route.params.id) || -1)
      .then((data: QS[]) => {
        this.qsLinked = data;
        this.loading = false;
      });
  },
  computed: {
    headers() {
      return [
        {
          text: "fdf",
          align: "start",
          value: "prependAction",
          sortable: false,
          class: "black--text",
        },
        {
          text: this.$t("firmware.table.headers.seriesNumber"),
          align: "start",
          sortable: true,
          value: "serialNumber",
          class: "black--text",
        },
        {
          text: this.$t("firmware.table.headers.statut"),
          sortable: false,
          value: "statut",
          class: "black--text",
        },
        {
          text: this.$t("firmware.table.headers.QSName"),
          sortable: true,
          value: "name",
          class: "black--text",
        },
        {
          text: this.$t("firmware.table.headers.firmwareVersion"),
          sortable: true,
          value: "actualFirmware",
          class: "black--text",
        },
        {
          text: this.$t("firmware.table.headers.MACAddress"),
          sortable: true,
          value: "id",
          class: "black--text",
        },
      ];
    },
  },
  created() {
    window.addEventListener("keydown", (e) => {
      if (e.key == "Shift") {
        this.keyPressed = true;
      }
    });
    window.addEventListener("keyup", (e) => {
      if (e.key == "Shift") {
        this.keyPressed = false;
      }
    });
    bus.$on("updateInformationFrom", (newForm: any, saved: boolean) => {
      this.informationForm = { ...newForm, hasBeenSaved: saved };
    });
  },
  watch: {
    qsSelected() {
      this.selectableItems = (this.$refs.myTable as any).selectableItems.filter(
        (item: QS) =>
          !item.isPublished(this.firmware) && !item.isInstalled(this.firmware)
      ).length;
      if (this.qsSelected.length === this.selectableItems) {
        this.selectAllBoolean = true;
      } else if (
        this.selectAllBoolean &&
        this.qsSelected.length !== this.selectableItems
      ) {
        this.selectAllBoolean = false;
      }
    },
  },
  methods: {
    getStatutColor(qs: QS): string {
      if (qs.isInstalled(this.firmware)) {
        return "green";
      } else if (qs.isPublished(this.firmware)) {
        return "blue";
      } else {
        return "red";
      }
    },
    getStatutText(qs: QS): string {
      if(qs.hasABetterVersionThan(this.firmware)){
        return this.$t("firmware.table.content.hasBetterVersion").toString(); 
      } else if (qs.isInstalled(this.firmware)) {
        return this.$t("firmware.table.content.statutUpToDate").toString();
      } else if (qs.isPublished(this.firmware)) {
        return this.$t("firmware.table.content.statutReleased").toString();
      } else {
        return this.$t("firmware.table.content.statutNotReleased").toString();
      }
    },
    rowClicked(qs: QS) {
      if (this.keyPressed) {
        const start = (this.$refs.myTable as any).selectableItems.indexOf(
          this.qsSelected[this.qsSelected.length - 1]
        );
        const end = (this.$refs.myTable as any).selectableItems.indexOf(qs);

        if (end > start) {
          for (let i = start + 1; i <= end; i++) {
            this.addOnly((this.$refs.myTable as any).selectableItems[i]);
          }
        } else {
          for (let i = end; i <= start - 1; i++) {
            this.addOnly((this.$refs.myTable as any).selectableItems[i]);
          }
        }
      } else {
        this.addOrRemoveQSTolist(qs);
      }
    },
    selectAll() {
      this.qsSelected = [];
      this.selectAllBoolean = !this.selectAllBoolean;
      if (!this.selectAllBoolean) {
        this.qsSelected = [];
      } else {
        const index = this.qsLinked.indexOf(
          (this.$refs.myTable as any).selectableItems[0]
        );

        for (
          let i = index;
          i < index + (this.$refs.myTable as any).selectableItems.length;
          i++
        ) {
          this.addOrRemoveQSTolist(this.qsLinked[i]);
        }
      }
    },
    addOrRemoveQSTolist(qs: QS) {
      const index = this.qsSelected.indexOf(qs);
      if (index !== -1) {
        this.qsSelected.splice(index, 1);
      } else if (
        this.getStatutText(qs) ===
        this.$t("firmware.table.content.statutNotReleased").toString()
      ) {
        this.qsSelected.push(qs);
      }
    },
    addOnly(qs: QS) {
      const index = this.qsSelected.indexOf(qs);
      if (
        index === -1 &&
        !qs.isInstalled(this.firmware) &&
        !qs.isPublished(this.firmware)
      ) {
        this.qsSelected.push(qs);
      }
    },
    isSelected(item: QS) {
      return this.qsSelected.indexOf(item) !== -1;
    },
    onPageChange() {
      this.qsSelected = [];
    },
    releaseFirmware() {
    
      const values = Object.values(this.qsSelected).map((x) => x.id);
      this.$services.qs
        .updateQSReleasdFirmware((this.firmware?.version || -1), values as string[])
        .then((data: any) => {
          this.$services.qs
            .getQSWithFirmware(Number(this.$route.params.id) || -1)
            .then((data: QS[]) => {
              this.qsLinked = data;
              this.releaseUpdateDialogVisible = false;
              this.updateReleasedSuccessfullyDialogVisible = true;
            });
            this.$services.logs.insertFirmwareReleaseLog((this.firmware?.version?.toString() || ""), values as string[]);
            
        })
        .catch((err: any) => {
          this.releaseUpdateDialogVisible = false;
          this.updateReleaseErrorDialogVisible = true;
        });
    },
    getRowClass(item: QS): string {
      if (item.isPublished(this.firmware) || item.isInstalled(this.firmware)) {
        return "unselectableRow";
      }
      return "";
    },
    getDialogVisibleDependOnInformationForm() {
      if (this.informationForm.hasBeenSaved === false) {
        this.errorInformationDialog.content = this.$t('firmware.QSList.errorInformationDialog.contentNotSaved').toString();
        this.errorInformationDialog.visible = true;
      } else if (this.informationForm.message.fr === "" || this.informationForm.versionString === "") {
        this.errorInformationDialog.content = this.$t('firmware.QSList.errorInformationDialog.contentMissingFrDesc').toString();
        this.errorInformationDialog.visible = true;
      } else {
        this.releaseUpdateDialogVisible = true;
      }
    },
    hideSuccessDialog(){
      this.updateReleasedSuccessfullyDialogVisible = false; 
      this.$router.push("/firmwares");
    }
  },
});
