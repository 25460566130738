
import Firmware from "@/data/models/firmware";
import Dialog from "@/components/molecules/Dialog.vue";
import Vue from "vue";
import { bus } from "@/main";

export default Vue.extend({
  components: { Dialog },
  name: "Information",
  data() {
    return {
      form: {
        versionString: "",
        message: {
          fr: "",
          en: "",
          de: "",
        },
      },
      initialForm: {
        versionString: "",
        message: {
          fr: "",
          en: "",
          de: "",
        },
      },
      loading: false,
      informationDialogVisible: false,
      dialog: {
        title: "",
        message: "",
      },
      initialFirmwareInformation: {
        versionString: "",
        message: { fr: "", en: "", de: "" },
      },
    };
  },
  props: {
    firmware: {
      type: Firmware,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
  },
  computed: {},
  methods: {
    filterObjectOnSelectedLang(obj: any) {
      return Object.keys(obj)
        .filter((key) => obj[key] !== "")
        .reduce((cur, key) => {
          return Object.assign(cur, {
            [key]: obj[key],
          });
        }, {});
    },
    onSubmit() {
      this.loading = true;
      this.$services.firmwares
        .updateMessageAndVersionString(
          Number(this.$route.params.id),
          this.filterObjectOnSelectedLang(this.form.message),
          this.form.versionString || ""
        )
        .then(() => {
          if (this.form.message !== this.initialFirmwareInformation.message) {
            this.$services.logs.insertFirmwareUpdateDescriptionLog(
              this.firmware
            );
            this.initialFirmwareInformation = {
              message: this.form.message,
              versionString: this.form.versionString,
            };
          }
          if (
            this.form.versionString !==
            this.initialFirmwareInformation.versionString
          ) {
            this.$services.logs.insertFirmwareUpdateVersionNumberLog(
              this.firmware
            );
            this.initialFirmwareInformation = {
              message: this.form.message,
              versionString: this.form.versionString,
            };
          }

          this.dialog = {
            title: this.$t("firmware.dialogUpdateInformation.title").toString(),
            message: this.$t(
              "firmware.dialogUpdateInformation.content"
            ).toString(),
          };

          this.initialForm = JSON.parse(JSON.stringify(this.form));
          bus.$emit("updateInformationFrom", this.form, true);
        })
        .catch(() => {
          this.dialog = {
            title: this.$t(
              "firmware.dialogUpdateInformation.errorTitle"
            ).toString(),
            message: this.$t(
              "firmware.dialogUpdateInformation.errorContent"
            ).toString(),
          };
        })
        .finally(() => {
          this.loading = false;
          this.informationDialogVisible = true;
        });
    },
    emitForm(saved: boolean) {
      if (
        JSON.stringify(this.initialForm) === JSON.stringify(this.form) &&
        JSON.stringify(this.initialForm.message) ===
          JSON.stringify(this.form.message)
      ) {
        bus.$emit("updateInformationFrom", this.form, true);
      } else {
        bus.$emit("updateInformationFrom", this.form, saved);
      }
      
    },
  },
  watch: {
    firmware(newFirmware) {
      this.initialFirmwareInformation = newFirmware;
      this.form = {
        versionString: newFirmware.versionString,
        message: {
          fr: newFirmware.message?.fr || "",
          en: newFirmware.message?.en || "",
          de: newFirmware.message?.de || "",
        },
      };
      
      this.initialForm = JSON.parse(JSON.stringify(this.form));
      bus.$emit("updateInformationFrom", this.form, true);
    },
  },
});
