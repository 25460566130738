
import Firmware from "@/data/models/firmware";
import Information from "@/components/organisms/firmware/Information.vue";
import QSList from "@/components/organisms/firmware/QSList.vue";
import Vue from "vue";
import QS from "@/data/models/qs";

export default Vue.extend({
  components: { Information, QSList },
  name: "Firmware",
  data() {
    return {
      firmware: null as Firmware | null,
      QSLinked: [] as QS[],
      firmwareLoading: true
    };
  },
  computed: {
    title(): string {
      return `${this.$t("firmware.title")} / ${this.$route.params.id || ""}`;
    },
  },
  mounted() {
    this.$services.firmwares
      .getFirmware(Number(this.$route.params.id))
      .then((data) => {
        this.firmware = data;
      }).finally(()=>{
        this.firmwareLoading = false;
      });
  },
  methods: {},
});
